import React from "react";

import { parseRuleQuery, translateRuleToPlainText } from "services/rules";
import { useVariables } from "services/variables";

export const RuleQueryInlineView = ({
  ruleQuery,
  withPrefix,
}: {
  ruleQuery: string | null | undefined;
  withPrefix?: boolean;
}) => {
  const { variables } = useVariables();

  if (!ruleQuery)
    return withPrefix ? <>Available to everyone</> : <>Everyone</>;

  const parsedRule = parseRuleQuery(ruleQuery);

  if (!parsedRule) return withPrefix ? <>Availability unknown</> : <>Unknown</>;

  const getVariableName = (id: string): string =>
    variables?.find((v) => v._id === id)?.name || "unknown_variable";

  return (
    <>
      {withPrefix ? "Available if " : ""}
      {translateRuleToPlainText(parsedRule, getVariableName)}
    </>
  );
};
