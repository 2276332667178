import { createSlice } from "@reduxjs/toolkit";

import { type ProactiveConversationsState } from "./types";

const initialState: ProactiveConversationsState = {
  toastNotification: null,
};

const proactiveConversations = createSlice({
  name: "proactiveConversations",
  initialState,
  reducers: {
    setToastNotification(state, action) {
      return { ...state, toastNotification: action.payload };
    },
    clearToastNotification(state) {
      return { ...state, toastNotification: null };
    },
  },
});

export const { setToastNotification, clearToastNotification } =
  proactiveConversations.actions;
export const { reducer: proactiveConversationsReducer } =
  proactiveConversations;
