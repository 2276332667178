import { primitives, tokens } from "@adasupport/byron";
import React from "react";
import styled from "styled-components";

const Line = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  color: ${tokens.colors.text.muted};
  margin: ${primitives.spacing.space6} 0;
  &::before,
  &::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid ${tokens.colors.border.default};
  }
  &:not(:empty)::before {
    margin-right: ${primitives.spacing.space4};
  }
  &:not(:empty)::after {
    margin-left: ${primitives.spacing.space4};
  }
`;

/**
 * Horizontal line with optional text in the middle
 */
export function HR({ children }: { children?: string }) {
  return <Line>{children}</Line>;
}
