import { Icon, primitives, tokens } from "@adasupport/byron";
import React, { type FC, type PropsWithChildren } from "react";
import styled from "styled-components";

const SectionWaypoint = styled.div<{ backgroundColor?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${primitives.spacing.space1};
  border-radius: 8px;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || tokens.colors.background.muted};
  position: absolute;
  top: -${primitives.spacing["space1.5"]};
  right: 100%;
  margin-right: ${primitives.spacing.space2};
`;

const S = {
  EditorSection: styled.div`
    position: relative;

    &:focus-within {
      ${SectionWaypoint} {
        background-color: ${primitives.palette.plum100};
        color: ${primitives.palette.plum600};
      }
    }
  `,
  SectionWaypoint,
};

interface Props {
  icon: keyof typeof Icon;
  backgroundColor?: string;
  className?: string;
}

export const EditorSection: FC<PropsWithChildren<Props>> = ({
  icon,
  backgroundColor,
  children,
  className = "",
}) => {
  const IconComponent = Icon[icon];

  return (
    <S.EditorSection className={className}>
      <S.SectionWaypoint backgroundColor={backgroundColor}>
        <IconComponent width={24} />
      </S.SectionWaypoint>
      {children}
    </S.EditorSection>
  );
};
