import jmespath from "jmespath";

import { parseRuleQuery, ruleHasInvalidFields } from "services/rules";
import { isValidJSONString, isValidXMLString } from "services/strings";
import { type Variable } from "services/variables";

import { type WebAction } from "./api";
import { WebActionCharacterLimits } from "./constants";
import {
  getReferencedInferredVariables,
  getReferencedVariables,
  getWebActionInputNameValidationMessage,
  isInferredVariableReferenced,
  replaceVariablesWithValues,
} from "./helpers";

const isValidJMESPath = (str: string): boolean => {
  if (!str) return false;

  try {
    jmespath.compile(str);
  } catch (e) {
    if (e instanceof Error && ["ParserError", "LexerError"].includes(e.name)) {
      return false;
    }
  }

  return true;
};

export const getWebActionInvalidFields = (
  webAction: Omit<WebAction, "_id">,
  variables: Variable[],
) => {
  const res: Partial<
    Record<
      | keyof typeof webAction
      | `inputs.${number}.name`
      | `inputs.${number}.description`
      | `outputs.${number}.key`
      | `outputs.${number}.name`
      | `headers.${number}.name`
      | `headers.${number}.value`,
      { message: string }
    >
  > = {};

  if (!webAction.name) {
    res.name = { message: "Required" };
  } else if (webAction.name.length > WebActionCharacterLimits.name) {
    res.name = { message: "Name is too long" };
  }

  if (!webAction.description) {
    res.description = { message: "Required" };
  } else if (
    webAction.description.length > WebActionCharacterLimits.description
  ) {
    res.description = { message: "Description is too long" };
  }

  if (!webAction.url) {
    res.url = { message: "Required" };
  }

  if (
    webAction.method === "POST" ||
    webAction.method === "PUT" ||
    webAction.method === "PATCH"
  ) {
    // user-provided test values may not exist if the user is just updating something like the action description
    // and the JSON will be invalid if we don't replace variable template strings with values
    const defaultTestValues = {
      long: "1",
      bool: "true",
      string: "a",
      text: "a",
      number: "1",
      boolean: "true",
    };

    const testValuesForVariables = getReferencedVariables(
      webAction,
      variables,
    ).map((v) => ({
      [v._id]: defaultTestValues[v._type as keyof typeof defaultTestValues],
    }));

    const testValuesForInferredVariables = getReferencedInferredVariables(
      webAction,
    ).map((v) => ({
      [v.name]: defaultTestValues[v.type as keyof typeof defaultTestValues],
    }));

    const testValues = Object.assign(
      {},
      ...testValuesForInferredVariables,
      ...testValuesForVariables,
    );
    const body = replaceVariablesWithValues(webAction.request_body, testValues);

    if (webAction.request_body.length > 0) {
      if (webAction.content_type === "json") {
        if (!isValidJSONString(body)) {
          res.request_body = {
            message: "Invalid JSON",
          };
        }
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      } else if (webAction.content_type === "xml") {
        if (!isValidXMLString(body)) {
          res.request_body = {
            message: "Invalid XML",
          };
        }
      }

      if (body.length > WebActionCharacterLimits.requestBody) {
        res.request_body = {
          message: "Request body is too long",
        };
      }
    }
  }

  webAction.inputs.forEach((input, index) => {
    const validationMessage = getWebActionInputNameValidationMessage(input);

    if (validationMessage) {
      res.inputs = { message: "Inputs are invalid" };
      res[`inputs.${index}.name`] = {
        message: validationMessage,
      };
    } else if (
      webAction.inputs.findIndex((i) => i.name === input.name) !== index
    ) {
      res.inputs = { message: "Inputs are invalid" };
      res[`inputs.${index}.name`] = {
        message: "Duplicate input name",
      };
    } else if (!isInferredVariableReferenced(webAction, input.name)) {
      res.inputs = { message: "Inputs are invalid" };
      res[`inputs.${index}.name`] = {
        message: "Not referenced in API call",
      };
    }
  });

  webAction.outputs.forEach((output, index) => {
    if (webAction.content_type === "json" && !isValidJMESPath(output.key)) {
      res.outputs = { message: "Outputs are invalid" };
      res[`outputs.${index}.key`] = {
        message: "Invalid path",
      };
    } else if (output.key.length > WebActionCharacterLimits.outputKey) {
      res.outputs = { message: "Outputs are invalid" };
      res[`outputs.${index}.key`] = {
        message: "Key is too long",
      };
    }

    if (!output.name) {
      res.outputs = { message: "Outputs are invalid" };
      res[`outputs.${index}.name`] = {
        message: "An output name must be provided",
      };
    }

    if (
      output.name &&
      output.name.length > WebActionCharacterLimits.outputName
    ) {
      res.outputs = { message: "Outputs are invalid" };
      res[`outputs.${index}.name`] = {
        message: "Name is too long",
      };
    }
  });

  webAction.headers.forEach((header, index) => {
    if (!header.name.trim()) {
      res[`headers.${index}.name`] = {
        message: "Required",
      };
    } else if (header.name.length > WebActionCharacterLimits.headerName) {
      res[`headers.${index}.name`] = {
        message: "Header name is too long",
      };
    }

    if (!header.value.trim()) {
      res[`headers.${index}.value`] = {
        message: "Required",
      };
    } else if (header.value.length > WebActionCharacterLimits.headerValue) {
      res[`headers.${index}.value`] = {
        message: "Header value is too long",
      };
    }
  });

  if (webAction.rules) {
    const ruleset = parseRuleQuery(webAction.rules);

    if (!ruleset || ruleHasInvalidFields(ruleset)) {
      res.rules = { message: "Invalid ruleset" };
    }
  }

  return res;
};
