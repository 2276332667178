import { primitives } from "@adasupport/byron";
import React from "react";

import * as S from "./styles";

export const HowThisWorksModal = () => (
  <S.Container>
    <S.Video
      allowFullScreen
      src="https://fast.wistia.net/embed/iframe/al4pv4abui?seo=true&videoFoam=true&fullscreenButton=true"
    />
  </S.Container>
);

HowThisWorksModal.isLarge = true;
HowThisWorksModal.isVideo = true;
HowThisWorksModal.closeColor = primitives.palette.white;
