import chameleon from "@chamaeleonidae/chmln";
import { type AxiosResponse } from "axios";

import { fetchClientAction, updateClientAction } from "actions/client";
import { generateNewResponseRecord } from "components/Common/ModalResponseDetails/helpers";
import { type Platform } from "reducers/platforms/types";
import { type ResponseRecord } from "reducers/responses/types";
import { type TagType } from "reducers/tags/types";
import { type ClientAttributes } from "resourceModels/Client";
import { getResource } from "selectors/resources";
import { adaAPI, adaApiRequest } from "services/api";
import { selectClient } from "services/client";
import { saveClientAction } from "services/client/actions";
import { keyConverter } from "services/key-converter";
import { storage } from "services/storage";
import { UserAnalyticsService } from "services/userAnalytics/userAnalytics";

import { createAlert } from "./alerts";
import { AlertType } from "./alerts/types";
import { createExpressionAction, trainExpressions } from "./expressions";
import { closeModal, closeModalAction, openModalAction } from "./modal";
import { makeAsyncApiRequest, saveOne } from "./resources";
import { saveResponse } from "./responses";
import { setPage } from "./router";
import {
  type CallApiAction,
  type Credentials,
  type ThunkAction,
} from "./types";

export {
  createMessage,
  saveResponse,
  updateResponse,
  deleteResponse,
  fetchAllResponsesShallow,
  getResponseById,
} from "./responses";

export {
  handleLiveToggle,
  toggleResponseStatus,
} from "./responses/toggleLiveStatus";

export {
  getAllVariables,
  createUnsavedVariable,
  updateVariable,
} from "./variables";

// *** GENERAL ***
export function setState(reducer: string, payload: Record<string, unknown>) {
  return {
    type: `SET_${reducer}`,
    payload,
  };
}

export const setStateAction = setState;

/**
 * A flag to ensure PostHog session is initiated only once
 */
let isProductTourServiceLoaded = false;

/**
 * Start a posthog session if the app is running in a production browser
 */
export function attemptStartProductAnalyticsLogging(): ThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    const client = selectClient(state);
    const user = getResource(state, "user");

    if (!client || !user) {
      return;
    }

    // Test to see if this app instance is running in production
    if (window.location.origin.endsWith(".ada.support")) {
      UserAnalyticsService.init(client, user);

      // We could make this a service like UserAnalyticsService for consistency's sake.
      // Also, we can use PII here because chameleon is SOC2 compliant.
      if (!isProductTourServiceLoaded) {
        chameleon.init(
          "SWaIoP17t4gQpcmtJSTyEgbkr7Iwo9fvKcIf3cWCT0Q2Jg-1S410l-F8XnIUXTj5ZwewOA",
          { fastUrl: "https://fast.chameleon.io/" },
        );

        chameleon.identify(user.id, {
          email: user.email,
          name: user.name,
          created: user.created,
          role: user.role,
          company: {
            uid: client.id,
            name: client.name,
            client_handle: client.handle,
            // eslint-disable-next-line no-restricted-syntax
            bot_sku: client.product_type,
            bot_status: client.botStatus,
            trial_end_date: client.trialEndDate,
            bat_features: client.features,
          },
        });

        isProductTourServiceLoaded = true;
      }
    }
  };
}

// *** PLATFORMS ***
/**
 * Action creator to update the platform
 * `platform` - the platform type
 * `payload` - values to update on the platform record
 * `metadata` - values to update on the platform map root (isPublished, etc.)
 */
export function updatePlatform(
  platform: string,
  payload: unknown,
  metadata: unknown = null,
) {
  return {
    type: "UPDATE_PLATFORM",
    platform,
    payload,
    metadata,
  };
}

export function removePlatform(platformName: string) {
  return {
    type: "PLATFORM_DELETE_SUCCESS",
    platformName,
  };
}

/**
 * Silently updates a platform without showing a notification
 */
export function saveClientPlatformSilent(platform: Platform) {
  const payload = keyConverter(platform.get("record").toJS(), "underscore");
  const platformName = platform.getIn(["record", "name"]);
  const method = platform.get("isSaved") ? "patch" : "post";

  let endpoint = "/platforms/";

  if (method === "patch") {
    endpoint = `/platforms/${platformName}/`;
  }

  return {
    // TODO BUIL-690: deprecate CALL_API (use adaAPI directly instead)
    CALL_API: {
      method,
      endpoint,
      payload,
      args: {
        platform,
      },
      types: [
        "PLATFORM_POST_REQUEST",
        "PLATFORM_POST_SUCCESS",
        "PLATFORM_POST_FAILURE",
      ],
    },
  };
}

/**
 * Re-fetches the entire client object
 */
export function refreshClient() {
  return {
    // TODO BUIL-690: deprecate CALL_API (use adaAPI directly instead)
    CALL_API: {
      method: "get",
      endpoint: "/",
      types: ["PLATFORM_REFRESH_REQUEST", "PLATFORM_REFRESH_SUCCESS"],
    },
  };
}

export function saveClientPlatform(
  platform: Platform,
  closeAfterSave = true,
  enableOnly = false,
) {
  const data = keyConverter(platform.get("record").toJS(), "underscore");
  let payload;

  if (enableOnly) {
    payload = { name: data.name, enabled: data.enabled };
  } else {
    payload = data;
  }

  if (platform.get("isPublished") || platform.get("isSaved")) {
    return {
      // TODO BUIL-690: deprecate CALL_API (use adaAPI directly instead)
      CALL_API: {
        method: "patch",
        endpoint: `/platforms/${platform.getIn(["record", "name"])}/`,
        payload,
        args: {
          platform,
        },
        types: [
          "PLATFORM_UPDATE_REQUEST",
          "PLATFORM_UPDATE_SUCCESS",
          "PLATFORM_UPDATE_FAILURE",
        ],
        dispatchCallbacks: [
          {
            request: createAlert,
            fireOnStatus: 400,
          },
          {
            request: createAlert,
            fireOnStatus: 500,
          },
          {
            request: createAlert,
            fireOnStatus: "success",
            args: {
              message: "Settings have been successfully updated.",
              alertType: "success",
            },
          },
          closeAfterSave && {
            request: closeModal,
            fireOnStatus: "success",
          },
        ],
      },
    };
  }

  return {
    // TODO BUIL-690: deprecate CALL_API (use adaAPI directly instead)
    CALL_API: {
      method: "post",
      endpoint: "/platforms/",
      payload,
      args: {
        platform,
      },
      types: [
        "PLATFORM_POST_REQUEST",
        "PLATFORM_POST_SUCCESS",
        "PLATFORM_POST_FAILURE",
      ],
      dispatchCallbacks: [
        {
          request: createAlert,
          fireOnStatus: 400,
        },
        {
          request: createAlert,
          fireOnStatus: "success",
          args: {
            message: "Integration has been successfully created.",
            alertType: "success",
          },
        },
        closeAfterSave && {
          request: closeModal,
          fireOnStatus: "success",
        },
      ],
    },
  };
}

export function revokeVoiceCredentials(platform: Platform): ThunkAction {
  return async (dispatch) => {
    const payload = platform.get("record").toJS();

    try {
      await adaAPI.request({
        method: "DELETE",
        url: "/voice/revoke_voice_credentials",
        data: {
          payload,
        },
      });
      dispatch(closeModalAction());
      dispatch(
        createAlert({
          message: "Credentials have been revoked successfully",
          alertType: "success",
        }),
      );
    } catch (error) {
      dispatch(closeModalAction());
      dispatch(
        createAlert({
          message: "Failed to revoke credentials.",
          alertType: "error",
        }),
      );
    }
  };
}

export function deletePlatform(platform: Platform) {
  return {
    // TODO BUIL-690: deprecate CALL_API (use adaAPI directly instead)
    CALL_API: {
      method: "delete",
      endpoint: `/platforms/${platform.get("record").name}/`,
      args: {
        platformName: platform.get("record").name,
      },
      types: [
        "PLATFORM_DELETE_REQUEST",
        "PLATFORM_DELETE_SUCCESS",
        "PLATFORM_DELETE_FAILURE",
      ],
      dispatchCallbacks: [
        {
          request: createAlert,
          fireOnStatus: 400,
        },
        {
          request: createAlert,
          fireOnStatus: "success",
          args: {
            message: "Platform has been successfully deleted.",
            alertType: "success",
          },
        },
        {
          request: closeModal,
          fireOnStatus: "success",
        },
      ],
    },
  };
}

/**
 * Reset the password of a user with a valid email
 */
export function resetPassword(email: string) {
  return {
    // TODO BUIL-690: deprecate CALL_API (use adaAPI directly instead)
    CALL_API: {
      method: "post",
      endpoint: "/users/reset/",
      payload: {
        email,
      },
      types: [
        "RESET_PASSWORD_REQUEST",
        "RESET_PASSWORD_SUCCESS",
        "RESET_PASSWORD_FAILURE",
      ],
    },
  };
}

// *** TWO-FACTOR AUTHENTICATION ***
export function requestSMS(
  credentials:
    | Credentials
    | { response?: unknown; credentials: Credentials } = {},
): CallApiAction {
  let credentialsToPass: Credentials;

  // This is a bit of a hack. This function is *meant* to be called with a credentials object
  // that contains email and password. However, when it's called as a callback to activateTFA,
  // the callback takes args (credentials in this case) which are passed as a field of the larger
  // object that's passed as the argument to this function. The condition handles this case.
  if ("response" in credentials) {
    credentialsToPass = credentials.credentials;
  } else {
    credentialsToPass = credentials as Credentials;
  }

  return {
    // TODO BUIL-690: deprecate CALL_API (use adaAPI directly instead)
    CALL_API: {
      method: "post",
      endpoint: "/auth/2fa/sms/",
      payload: credentialsToPass as Record<string, unknown>,
      types: ["SMS_REQUEST", "SMS_SUCCESS", "SMS_FAILURE"],
    },
  };
}

export function showLoginError() {
  return {
    type: "SET_LOGIN_ERROR",
  };
}

/**
 * Updates the session state after the user is fetched as part of the login flow
 */
export function updateSessionAfterLogin(): ThunkAction {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_SESSION_AFTER_LOGIN",
    });
  };
}

export function loginUser(
  username: string,
  password: string,
  isSSO = false,
): ThunkAction {
  return (dispatch, getState) => {
    dispatch({
      type: "LOGIN_REQUEST",
    });

    dispatch({
      type: "SET_CREDENTIALS",
      payload: {
        email: username,
        password,
      },
    });

    // Authenticate by hitting /auth/
    const pendingRequest = dispatch(
      makeAsyncApiRequest({
        method: "post",
        endpoint: "/auth/",
        payload: {
          username,
          password,
          is_sso: isSSO,
          tfa_token: getState().login.tfa_token,
        },
      }),
    )
      .then((response: AxiosResponse) => {
        // We have to do things this ugly way because if we do fetchOne("user") it'll fail with a
        // 401 if enforce_mfa is turned on and the user doesn't have MFA set up. Instead, we
        // manually dispatch the corresponding actions using the response from /auth/.
        dispatch({
          type: "FETCH_ONE_USER_SUCCESS",
          requestKey: "default",
          response,
        });

        const { csrf } = response.data;

        if (csrf) {
          storage.store("csrf", csrf);
        }

        dispatch(fetchClientAction());
        dispatch(updateSessionAfterLogin());

        dispatch({
          type: "CLEAR_CREDENTIALS",
        });
      })
      .catch((response: AxiosResponse) => {
        if (response.status === 401) {
          dispatch({
            type: "FETCH_ONE_USER_SUCCESS",
            requestKey: "default",
            response,
          });

          return dispatch({
            type: "SET_IS_MFA_SETUP_REQUIRED",
          });
        }

        dispatch({
          type: "FETCH_ONE_USER_FAILURE",
          requestKey: "default",
          response,
        });

        dispatch({
          type: "LOGIN_FAILURE",
          response,
        });

        if (response.status === 429) {
          dispatch({
            type: "LOGIN_RATE_LIMIT",
          });
        }

        if (response.status === 418) {
          dispatch(requestSMS({ username, password }));
        }

        return dispatch(showLoginError());
      });

    dispatch({
      type: "FETCH_ONE_USER_REQUEST",
      requestKey: "default",
      pendingRequest,
    });
  };
}

export function activateTFA(
  countryCode: string,
  phoneNumber: string,
  userId: string,
  calledFromEnforceMFA = false,
): ThunkAction {
  return (dispatch, getState) => {
    const { login } = getState();
    // These are needed if the user doesn't already have a valid session, which occurs during the
    // enforce MFA flow.
    const { email, password } = login;

    let types;

    if (calledFromEnforceMFA) {
      types = [
        "ENFORCE_MFA_ACTIVATE_TFA_REQUEST",
        "ENFORCE_MFA_ACTIVATE_TFA_SUCCESS",
        "ENFORCE_MFA_ACTIVATE_TFA_FAILURE",
      ];
    } else {
      types = [
        "ACTIVATE_TFA_REQUEST",
        "ACTIVATE_TFA_SUCCESS",
        "ACTIVATE_TFA_FAILURE",
      ];
    }

    return dispatch({
      // TODO BUIL-690: deprecate CALL_API (use adaAPI directly instead)
      CALL_API: {
        method: "post",
        endpoint: `/users/${userId}/2fa/`,
        payload: {
          country_code: countryCode,
          phone_number: phoneNumber,
          username: email,
          password,
        },
        types,
        dispatchCallbacks: [
          {
            request: requestSMS,
            fireOnStatus: 418,
            args: {
              credentials: {
                username: email,
                password,
              },
            },
          },
          {
            request: createAlert,
            fireOnStatus: 400,
          },
        ] as CallApiAction["CALL_API"]["dispatchCallbacks"],
      },
    });
  };
}

/**
 * Checks that the user has entered the correct SMS code
 * smsCode - the SMS code that the user receives via text message
 * loginCallback - true if the user should be logged in on a successful check
 * calledFromEnforceMFA - true if the call comes from the enforce_mfa flow
 */
export function checkTFA(
  smsCode: string,
  loginCallback = true,
  calledFromEnforceMFA = false,
): ThunkAction {
  return (dispatch, getState) => {
    const { email, password } = getState().login;
    // TODO BUIL-690: deprecate CALL_API (use adaAPI directly instead)
    let dispatchCallbacks: CallApiAction["CALL_API"]["dispatchCallbacks"];
    let types;

    if (loginCallback) {
      dispatchCallbacks = [
        {
          request() {
            dispatch(loginUser(email, password));
          },
          fireOnStatus: "success",
        },
      ];
    } else {
      dispatchCallbacks = [];
    }

    if (calledFromEnforceMFA) {
      types = [
        "ENFORCE_MFA_SMS_VERIFY_REQUEST",
        "ENFORCE_MFA_SMS_VERIFY_SUCCESS",
        "ENFORCE_MFA_SMS_VERIFY_FAILURE",
      ];
    } else {
      types = [
        "SMS_VERIFY_REQUEST",
        "SMS_VERIFY_SUCCESS",
        "SMS_VERIFY_FAILURE",
      ];
    }

    return dispatch({
      // TODO BUIL-690: deprecate CALL_API (use adaAPI directly instead)
      CALL_API: {
        method: "post",
        endpoint: "/auth/2fa/token/",
        payload: {
          username: email,
          password,
          sms_code: smsCode,
        },
        types,
        dispatchCallbacks,
      },
    });
  };
}

/**
 * Logout
 */

export function logoutUser(
  logoutCallback?: () => Promise<boolean>,
): ThunkAction {
  return async (dispatch, getState) => {
    const isPasswordExpired =
      getState().login.lastLoginFailureReason === "password_expired";
    await dispatch(adaApiRequest({ method: "POST", url: "/auth/logout" }));

    if (logoutCallback) {
      await logoutCallback();
    }

    dispatch({
      type: "UNAUTHENTICATE_SUCCESS",
    });

    /*
      If password is expired re-dispatch call after state is cleared to toggle
      notification in login page
    */
    if (isPasswordExpired) {
      dispatch({
        type: "SET_PASSWORD_EXPIRED",
      });
    }

    UserAnalyticsService.reset();
  };
}

export function saveClientAndAlertAction(successMessage?: string): ThunkAction {
  return async (dispatch) => {
    try {
      await dispatch(saveClientAction());
      dispatch(
        createAlert({
          message: successMessage || "Client has been successfully updated.",
          alertType: "success",
        }),
      );
    } catch (e) {
      dispatch(
        createAlert({
          message: "Something went wrong.",
          alertType: "error",
        }),
      );

      throw e;
    }
  };
}

/**
 * Updates the client in state and saves.
 */
export function patchClientAction(
  update: Partial<ClientAttributes>,
): ThunkAction {
  return (dispatch) => {
    // Update the record
    dispatch(updateClientAction(update));

    // Persist the update
    return dispatch(saveClientAction());
  };
}

export function saveUserAction(id: string): ThunkAction {
  return (dispatch) =>
    dispatch(saveOne("user", id)).then(
      () =>
        dispatch(
          createAlert({
            message: "Profile has been successfully updated.",
            alertType: AlertType.SUCCESS,
          }),
        ),
      () =>
        dispatch(
          createAlert({
            message: "Something went wrong.",
            alertType: AlertType.ERROR,
          }),
        ),
    );
}

// *** EXPRESSIONS ***
export function handleBatchTrain({
  ids,
  searchQuery,
  responseId,
  groupId,
}: {
  ids: string[];
  searchQuery: string;
  responseId: string;
  groupId?: string;
}): ThunkAction {
  return async (dispatch, getState) => {
    const client = selectClient(getState());

    if (client && responseId === "__new__") {
      await dispatch(setPage("/answers"));

      dispatch(
        openModalAction("MODAL_RESPONSE_DETAILS", {
          response: generateNewResponseRecord(client.language),
          onSave: async (updatedResponse: ResponseRecord) => {
            const res = await dispatch(saveResponse(updatedResponse));
            dispatch(
              trainExpressions({
                ids,
                search: "",
                responseId: res.data.response._id,
              }),
            );
          },
        }),
      );
    } else {
      dispatch(
        trainExpressions({
          ids,
          search: searchQuery,
          responseId,
          fetchNextExpressions: false,
          groupId,
        }),
      );
    }
  };
}

// *** LOGS ***
export function handleTrainLogs(
  log: { message: { body: string } },
  responseId: string,
): ThunkAction {
  return (dispatch, getState) => {
    const client = selectClient(getState());

    if (client && responseId === "__new__") {
      dispatch(setPage("answers"));
      dispatch(
        openModalAction("MODAL_RESPONSE_DETAILS", {
          response: generateNewResponseRecord(client.language),
          onSave: async (updatedResponse: ResponseRecord) => {
            const res = await dispatch(saveResponse(updatedResponse));
            dispatch(
              createExpressionAction(
                res.data.response._id,
                log.message.body,
                "MANUAL_ENTRY",
                true,
              ),
            );
          },
        }),
      );
    } else {
      dispatch(
        createExpressionAction(
          responseId,
          log.message.body,
          "MANUAL_ENTRY",
          true,
        ),
      );
    }
  };
}

export function handleUndoResponseChanges(responseId: string): ThunkAction {
  return (dispatch) => {
    // Legacy action
    dispatch({
      type: "UNDO_RESPONSE",
      responseId,
    });
  };
}

// *** TAGS ***
// TODO: this is a quick fix to prevent tags being
// fetched multiple times, which has a big impact on perf for larger clients.
// Ideally we move this to RTK Query
let tagsIsFetching = false;

export function getTags(): ThunkAction {
  return async (dispatch) => {
    if (tagsIsFetching) {
      return;
    }

    dispatch({ type: "GET_TAGS_REQUEST" });

    try {
      tagsIsFetching = true;
      const response = await dispatch(
        adaApiRequest({
          method: "GET",
          url: "/tags",
        }),
      );

      if (response.data.tags) {
        dispatch({ type: "GET_TAGS_SUCCESS", tags: response.data.tags });
      }
    } catch (error) {
      console.warn("Failed to get tags");
    } finally {
      tagsIsFetching = false;
    }
  };
}

export const fetchTagsAction = getTags;

export function createTag(response: unknown, name: string) {
  return {
    // TODO BUIL-690: deprecate CALL_API (use adaAPI directly instead)
    CALL_API: {
      method: "post",
      endpoint: "/tags/",
      payload: {
        name,
      },
      args: {
        responseRecord: response,
      },
      types: ["CREATE_TAG_REQUEST", "CREATE_TAG_SUCCESS", "CREATE_TAG_FAILURE"],
      dispatchCallbacks: [
        {
          request: createAlert,
          fireOnStatus: "error",
        },
      ],
    },
  };
}

export function updateTag(tag: TagType) {
  return {
    // TODO BUIL-690: deprecate CALL_API (use adaAPI directly instead)
    CALL_API: {
      method: "patch",
      endpoint: `/tags/${tag.id}`,
      payload: {
        name: tag.name,
        color: tag.color,
      },
      args: {
        tagId: tag.id,
      },
      types: ["UPDATE_TAG_REQUEST", "UPDATE_TAG_SUCCESS", "UPDATE_TAG_FAILURE"],
      dispatchCallbacks: [
        {
          request: createAlert,
          fireOnStatus: "error",
        },
      ],
    },
  };
}

export const updateTagAction = updateTag;

export function deleteTag(tagId: string) {
  return {
    // TODO BUIL-690: deprecate CALL_API (use adaAPI directly instead)
    CALL_API: {
      method: "delete",
      endpoint: `/tags/${tagId}`,
      args: {
        tagId,
      },
      types: ["DELETE_TAG_REQUEST", "DELETE_TAG_SUCCESS", "DELETE_TAG_FAILURE"],
      dispatchCallbacks: [
        {
          request: createAlert,
          fireOnStatus: 400,
        },
      ],
    },
  };
}

export const deleteTagAction = deleteTag;

// *** SOCKETS ***
export function setSocketConnection(connected: boolean) {
  return {
    type: "SET_SOCKET_CONNECTION",
    connected,
  };
}

// *** CONVERSATIONS ***

export function getAuths() {
  return {
    // TODO BUIL-690: deprecate CALL_API (use adaAPI directly instead)
    CALL_API: {
      method: "get",
      endpoint: "/auths/",
      types: ["GET_AUTHS_REQUEST", "GET_AUTHS_SUCCESS", "GET_AUTHS_FAILURE"],
    },
  };
}

// *** LIVE AGENTS *** //
/**
 * getLAGroupings
 * platformType such as zendesk_liveagent or nuance_liveagent
 * type of groupings being looked for
 * returns a collection of group ids mapping to group names
 */
export const getLAGroupings = (platformType: string, type: string) => ({
  // TODO BUIL-690: deprecate CALL_API (use adaAPI directly instead)
  CALL_API: {
    method: "get",
    endpoint: `/platforms/live_agent/groupings/?platform=${platformType}&type=${type}`,
    types: [
      "GET_LA_GROUP_REQUEST",
      "GET_LA_GROUP_SUCCESS",
      "GET_LA_GROUP_FAILURE",
    ],
  },
});
