import { type StartUrl } from "components/Generative/Pages/Knowledge/SourcesItem/types";

export const IMPORT_OPTIONS = {
  CRAWL: "CRAWL",
  SPECIFIC_PAGES: "SPECIFIC_PAGES",
};

export const IS_URL_CONFIG = {
  require_protocol: true,
  require_valid_protocol: true,
  require_host: true,
  allow_query_components: true,
};

export const LEGACY_INITIAL_KNOWLEDGE_SOURCE_NAME =
  "Webpages Imported in Onboarding";
export const INITIAL_KNOWLEDGE_SOURCE_NAME = "Single webpage import";

export function checkDuplicateUrls(
  startUrls: StartUrl[],
  existingStartUrls: StartUrl[],
) {
  return startUrls.some((startUrl) => {
    const startUrlPath = startUrl.url;

    return existingStartUrls.some((existingUrl) => {
      const existingUrlPath = existingUrl.url;

      return (
        startUrlPath.startsWith(existingUrlPath) ||
        existingUrlPath.startsWith(startUrlPath)
      );
    });
  });
}

export function fixProtocolAndTrimUrl(url: string) {
  const trimmedUrl = url.trim();

  if (!trimmedUrl.startsWith("http://") && !trimmedUrl.startsWith("https://")) {
    return `https://${trimmedUrl}`;
  }

  return trimmedUrl;
}
