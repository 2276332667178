import { Icon } from "@adasupport/byron";
import React from "react";
import styled from "styled-components";

const S = {
  TableSortArrow: styled.span`
    position: absolute;
    transform: translateY(-3px);
  `,
};

export const TableSortArrow = ({
  direction,
}: {
  direction: "asc" | "desc" | undefined;
}) =>
  direction ? (
    <S.TableSortArrow>
      {direction === "asc" ? <Icon.ArrowUp /> : <Icon.ArrowDown />}
    </S.TableSortArrow>
  ) : null;
