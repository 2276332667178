import Immutable from "immutable";
import isEqual from "lodash.isequal";
import isURL from "validator/lib/isURL";

import {
  type GetClientResponse,
  type PlatformType,
  type RawClient,
  type fetchClientSuccessAction,
  serializeClient,
} from "services/client";
import { keyConverter } from "services/key-converter";
import { convertToUnitTime } from "services/time";
import { type TypedMap } from "types";

import {
  ChatPlatformRecord,
  EmailPlatformRecord,
  HandoffIntegrationPlatformRecord,
  HttpPlatformRecord,
  MessengerPlatformRecord,
  NuanceLAPlatformRecord,
  type OraclePlatformRecord,
  type PlatformRecord,
  SalesforcePlatformRecord,
  SmsPlatformRecord,
  VoicePlatformRecord,
  ZendeskAgentWorkspacePlatformRecord,
  ZendeskLAPlatformRecord,
  ZendeskSupportPlatformRecord,
} from "./models";

type PlatformMap = TypedMap<{
  currentStep: number;
  isDirty: boolean;
  isLoading: boolean;
  isPublished: boolean;
  isSaved: boolean;
  isValid: boolean;
  record: PlatformRecord;
}>;

type PlatformsState = Immutable.Map<PlatformType, PlatformMap>;

function getCurrentZendeskStep(
  platform: PlatformMap,
  changed: Record<string, unknown> = {},
): number {
  if ("enabled" in changed && Object.keys(changed).length === 1) {
    // if only enabled has changed, don't change the step number
    return platform.get("currentStep");
  }

  if ((changed.auth || changed.accountKey) && platform.get("isDirty")) {
    // if anything in Step 1 has changed, need to go back to the start
    return 0;
  }

  if (
    platform.getIn(["record", "auth", "clientSecret"]) &&
    platform.getIn(["record", "auth", "clientId"]) &&
    platform.getIn(["record", "accountKey"])
  ) {
    if (platform.get("isDirty")) {
      if (platform.getIn(["record", "agentEmail"])) {
        // have all the information but haven't saved
        return 2;
      }

      // When we delete the agent email
      return 1;
    }

    if (platform.getIn(["record", "agentEmail"])) {
      // Here we are completely configured
      return 3;
    }

    return 1;
  }

  return 0;
}

/**
 * Validates the Salesforce Platform Record before an account can be linked
 */
export function validatePreSalesforcePlatform(
  platformRecord: SalesforcePlatformRecord,
) {
  const validURLs = [
    ["salesforceHostnameUrl"],
    ["auth", "instanceUrl"],
    ["auth", "redirectUri"],
  ]
    .map((path) =>
      isURL(platformRecord.getIn(path), { require_protocol: true }),
    )
    .every(Boolean);

  const validOtherFields = [
    ["organizationId"],
    ["deploymentId"],
    ["auth", "clientId"],
    ["auth", "clientSecret"],
  ]
    .map((path) => platformRecord.getIn(path))
    .every(Boolean);

  return validURLs && validOtherFields;
}

/**
 * Validates the full Salesforce Platform Record
 */
export function validateSalesforcePlatform(
  platformRecord: SalesforcePlatformRecord,
) {
  const validAccountFields = [
    ["auth", "email"],
    ["accessToken"],
    ["refreshToken"],
  ]
    .map((path) => platformRecord.getIn(path))
    .every(Boolean);

  return validatePreSalesforcePlatform(platformRecord) && validAccountFields;
}

/**
 * Validates the Oracle Platform Record before an account can be linked
 */
export function validatePreOraclePlatform(
  platformRecord: OraclePlatformRecord,
) {
  const validURLs = [["platformData", "oracleChatInstanceUrl"]]
    .map((path) =>
      isURL(platformRecord.getIn(path), { require_protocol: true }),
    )
    .every(Boolean);

  const validOtherFields = [
    ["platformData", "oracleUsername"],
    ["platformData", "oraclePassword"],
    ["handoffFields", "id"],
    ["enable"],
    ["name"],
    ["isIntegration"],
    ["iconUrl"],
  ]
    .map((path) => platformRecord.getIn(path))
    .every(Boolean);

  return validURLs && validOtherFields;
}

/**
 * Updates the expiryTime and expiryUnitOfTime in Chat Platform
 */
function updateChatExpiryTime(platformRecord: ChatPlatformRecord) {
  const results = convertToUnitTime(platformRecord.get("timeToExpiry"));

  if (results) {
    return {
      expiryUnitOfTime: results[0],
      expiryTime: results[1],
    };
  }

  return {};
}

/**
 * Retrieve and initialize the appropriate platform
 */
function getRecordType(
  platformType: PlatformType,
  payload: TypedMap<Record<string, unknown>>,
) {
  let record;

  if (payload.get("isIntegration")) {
    return new HandoffIntegrationPlatformRecord(payload);
  }

  switch (platformType) {
    case "sms":
      record = new SmsPlatformRecord(payload);
      break;
    case "messenger":
      record = new MessengerPlatformRecord(payload);
      break;
    case "nuance_liveagent":
      record = new NuanceLAPlatformRecord(payload);
      break;
    case "zendesk_liveagent":
      record = new ZendeskLAPlatformRecord(payload);
      break;
    case "salesforce_liveagent":
      record = new SalesforcePlatformRecord(payload);
      break;
    case "zaw_liveagent":
      record = new ZendeskAgentWorkspacePlatformRecord(payload);
      break;
    case "zendesk_support_liveagent":
      record = new ZendeskSupportPlatformRecord(payload);
      break;
    case "http":
      record = new HttpPlatformRecord(payload);
      break;
    case "voice":
      record = new VoicePlatformRecord(payload);
      break;
    case "email":
      record = new EmailPlatformRecord(payload);
      break;
    case "chat":
    default:
      record = new ChatPlatformRecord(payload);
  }

  return record;
}

function checkIfDirty(
  state: PlatformsState,
  platformType: string,
  newLastSavedPlatforms: PlatformsState,
) {
  const currentRecord = state.getIn([platformType, "record"]).toJS();
  const lastSavedRecord =
    newLastSavedPlatforms.size &&
    newLastSavedPlatforms.getIn([platformType, "record"]).toJS();

  return !isEqual(currentRecord, lastSavedRecord);
}

/**
 * Checks if platform fields are valid
 */
function checkIfValid(state: PlatformsState, platformType: PlatformType) {
  const untypedPlatformRecord = state.get(platformType)?.get("record");
  let isValid = true;

  if (!untypedPlatformRecord) {
    return false;
  }

  switch (platformType) {
    case "chat": {
      const platformRecord = untypedPlatformRecord as ChatPlatformRecord;

      if (platformRecord.rollout === null) {
        isValid = false;
      } else if (
        !isURL(state.getIn(["chat", "record", "privacyLink"]), {
          require_protocol: true,
        }) &&
        !state.getIn(["chat", "record", "showLogo"]) &&
        state.getIn(["chat", "record", "privacyLink"])
      ) {
        isValid = false;
      }

      break;
    }

    case "sms": {
      const platformRecord = untypedPlatformRecord as SmsPlatformRecord;

      if (!platformRecord.authId || !platformRecord.authKey) {
        isValid = false;
      }

      break;
    }

    case "messenger": {
      const platformRecord = untypedPlatformRecord as MessengerPlatformRecord;

      if (!platformRecord.accessToken) {
        isValid = false;
      }

      break;
    }

    case "zendesk_support_liveagent": {
      const platformRecord =
        untypedPlatformRecord as ZendeskSupportPlatformRecord;
      const { auth } = platformRecord;
      isValid = [
        auth.get("subdomain"),
        auth.get("email"),
        auth.get("apiToken"),
      ].every(Boolean);

      break;
    }

    case "zaw_liveagent": {
      const platformRecord =
        untypedPlatformRecord as ZendeskAgentWorkspacePlatformRecord;
      const { auth } = platformRecord;
      isValid = [
        auth.get("subdomain"),
        auth.get("email"),
        auth.get("apiToken"),
      ].every(Boolean);

      break;
    }

    case "nuance_liveagent": {
      const platformRecord = untypedPlatformRecord as NuanceLAPlatformRecord;

      if (
        !platformRecord.siteId ||
        !platformRecord.getIn(["auth", "clientId"]) ||
        !platformRecord.getIn(["auth", "clientSecret"]) ||
        !isURL(platformRecord.getIn(["auth", "redirectUri"]), {
          require_protocol: true,
        })
      ) {
        isValid = false;
      }

      break;
    }

    case "zendesk_liveagent": {
      const platformRecord = untypedPlatformRecord as ZendeskLAPlatformRecord;

      if (
        !platformRecord.agentEmail ||
        !platformRecord.getIn(["accountKey"]) ||
        !platformRecord.getIn(["auth", "clientId"]) ||
        !platformRecord.getIn(["auth", "clientSecret"]) ||
        !isURL(platformRecord.getIn(["auth", "redirectUri"]), {
          require_protocol: true,
        })
      ) {
        isValid = false;
      }

      break;
    }

    case "salesforce_liveagent": {
      const platformRecord = untypedPlatformRecord as SalesforcePlatformRecord;
      isValid = validateSalesforcePlatform(platformRecord);

      break;
    }

    default:
      break;
  }

  return isValid;
}

function setMetaData(
  state: PlatformsState,
  platformType: PlatformType,
  newLastSavedPlatforms: PlatformsState,
) {
  return state.mergeIn([platformType], {
    isDirty: checkIfDirty(state, platformType, newLastSavedPlatforms),
    isValid: checkIfValid(state, platformType),
  });
}

/**
 * Get the platform map
 */
export function getPlatformMap(
  platformType: PlatformType,
  isPublished = false,
  payload: TypedMap<Record<string, unknown>> = Immutable.Map(),
  isSaved = false,
): PlatformMap {
  const base: PlatformMap = Immutable.Map({
    record: getRecordType(platformType, payload),
    isLoading: false,
    isPublished,
    isDirty: false,
    isValid: platformType === "chat",
    // Need inSaved property for the ZD 2-step creation process
    isSaved,
  });
  let platformMap = base;

  if (platformType === "zendesk_liveagent") {
    platformMap = base.merge({
      currentStep: getCurrentZendeskStep(base),
    });
  } else if (platformType === "salesforce_liveagent") {
    platformMap = base.merge({
      isFormComplete: validatePreSalesforcePlatform(
        base.get("record") as SalesforcePlatformRecord,
      ),
    });
  } else if (platformType === "chat") {
    platformMap = base.mergeIn(
      ["record"],
      updateChatExpiryTime(base.get("record") as ChatPlatformRecord),
    );
  }

  return platformMap;
}

let lastSavedPlatforms: PlatformsState = Immutable.Map();

const platformsMap = Immutable.Map({
  chat: getPlatformMap("chat"),
  sms: getPlatformMap("sms"),
  messenger: getPlatformMap("messenger"),
  nuance_liveagent: getPlatformMap("nuance_liveagent"),
  zendesk_liveagent: getPlatformMap("zendesk_liveagent"),
  salesforce_liveagent: getPlatformMap("salesforce_liveagent"),
  zaw_liveagent: getPlatformMap("zaw_liveagent"),
  zendesk_support_liveagent: getPlatformMap("zendesk_support_liveagent"),
  oracle_liveagent: getPlatformMap("oracle"),
  intercom_liveagent: getPlatformMap("intercom"),
  http: getPlatformMap("http"),
  voice: getPlatformMap("voice"),
  email: getPlatformMap("email"),
}) as PlatformsState;

type PlatformsReducerAction =
  | ReturnType<typeof fetchClientSuccessAction>
  | { type: "PLATFORM_POST_REQUEST"; platform: PlatformMap }
  | { type: "PLATFORM_UPDATE_REQUEST"; platform: PlatformMap }
  | { type: "PLATFORM_UPDATE_SUCCESS"; response: { data: GetClientResponse } }
  | { type: "PLATFORM_REFRESH_SUCCESS"; response: { data: GetClientResponse } }
  | { type: "PLATFORM_POST_SUCCESS"; response: { data: GetClientResponse } }
  | { type: "PLATFORM_DELETE_SUCCESS"; platformName: PlatformType }
  | { type: "PLATFORM_UPDATE_FAILURE"; platform: PlatformMap }
  | { type: "PLATFORM_POST_FAILURE"; platform: PlatformMap }
  | {
      type: "UPDATE_PLATFORM";
      platform: PlatformType;
      payload: Record<string, unknown>;
      metadata?: Record<string, unknown>;
    }
  | { type: "PLATFORM_REFRESH_REQUEST"; [key: string]: unknown };

const createPlatformMapFromRawClient = (
  state: PlatformsState,
  rawClient: RawClient,
): PlatformsState => {
  let newState = state;

  if (rawClient.platforms) {
    rawClient.platforms.forEach((platform) => {
      const payload = Immutable.fromJS(keyConverter(platform));
      newState = newState
        .merge({
          [platform.name]: getPlatformMap(platform.name, true, payload, true),
        })
        .setIn([platform.name, "isLoading"], false);
      lastSavedPlatforms = newState;
      newState = setMetaData(newState, platform.name, lastSavedPlatforms);
    });
  }

  return newState;
};

export const platformsReducer = (
  state = platformsMap,
  action: PlatformsReducerAction,
): PlatformsState => {
  let nextState = state;

  switch (action.type) {
    case "PLATFORM_POST_REQUEST":
    case "PLATFORM_UPDATE_REQUEST":
      return nextState.setIn(
        [action.platform.getIn(["record", "name"]), "isLoading"],
        true,
      );

    case "FETCH_CLIENT_SUCCESS":
      return createPlatformMapFromRawClient(
        state,
        serializeClient(action.client),
      );

    case "PLATFORM_UPDATE_SUCCESS":
      return createPlatformMapFromRawClient(state, action.response.data.client);

    case "PLATFORM_REFRESH_SUCCESS":
      return createPlatformMapFromRawClient(state, action.response.data.client);

    case "PLATFORM_POST_SUCCESS":
      return createPlatformMapFromRawClient(state, action.response.data.client);

    case "PLATFORM_DELETE_SUCCESS":
      return nextState.setIn(
        [action.platformName],
        getPlatformMap(action.platformName),
      );
    case "PLATFORM_UPDATE_FAILURE":
    case "PLATFORM_POST_FAILURE":
      return nextState.setIn(
        [action.platform.getIn(["record", "name"]), "isLoading"],
        false,
      );
    case "UPDATE_PLATFORM":
      nextState = nextState.mergeDeepIn(
        [action.platform, "record"],
        action.payload,
      );
      nextState = setMetaData(nextState, action.platform, lastSavedPlatforms);

      if (action.metadata) {
        nextState = nextState.mergeIn([action.platform], action.metadata);
      }

      if (action.platform === "zendesk_liveagent") {
        nextState = nextState.setIn(
          [action.platform, "currentStep"],
          getCurrentZendeskStep(
            nextState.get("zendesk_liveagent") as PlatformMap,
            action.payload,
          ),
        );
      } else if (action.platform === "salesforce_liveagent") {
        nextState = nextState.setIn(
          [action.platform, "isFormComplete"],
          validatePreSalesforcePlatform(
            nextState.getIn(["salesforce_liveagent", "record"]),
          ),
        );
      } else if (action.platform === "oracle") {
        nextState = nextState.setIn(
          [action.platform, "isFormComplete"],
          validatePreOraclePlatform(nextState.getIn(["oracle", "record"])),
        );
      }

      return nextState;
    case "PLATFORM_REFRESH_REQUEST":
    default:
      return nextState;
  }
};
