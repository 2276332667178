import { Record } from "immutable";
import type React from "react";

import { type VariableRecord } from "services/variables";

export type FilterType =
  | "answerId"
  | "language"
  | "browser"
  | "device"
  | "channel"
  | "platform"
  | "interactionType"
  | "feedback"
  | "agentIds"
  | "blockType"
  | "isTestUser"
  | "includeIntros"
  | "locked"
  | "user"
  | "dashboardPage"
  | "variable"
  | "isAgentReview"
  | "chatterId"
  | "engaged"
  | "handoff"
  | "csatscore"
  | "conversationCategory"
  | "conversationTopics"
  | "activeState"
  | "source"
  | "arStatus"
  | "articleTag"
  | "coachingApplied"
  | "actionId"
  | "articleId"
  | "variableStatus";

export const booleanFilterTypes: FilterType[] = [
  "isTestUser",
  "locked",
  "includeIntros",
  "isAgentReview",
  "engaged",
  "handoff",
];

export const COMPARISON_OPERATOR_GREATER_THAN = {
  label: "Is Greater Than",
  icon: "GreaterThan",
  value: "GREATERTHAN",
};

export const COMPARISON_OPERATOR_LESS_THAN = {
  label: "Is Lesser Than",
  icon: "LessThan",
  value: "LESSTHAN",
};

export const ISPOSITIVE = "ISPOSITIVE";
export const ISNEGATIVE = "ISNEGATIVE";
export const MAX_CSAT_SCORE = "5";
export const MIN_CSAT_SCORE = "1";

export const INTERACTION_TYPES = [
  {
    id: "text",
    name: "Typed Message",
  },
  {
    id: "suggestion",
    name: "Quick Reply",
  },
  {
    id: "predictive_suggestion",
    name: "Suggestion",
  },
  {
    id: "clarification",
    name: "Clarification",
  },
  {
    id: "undefined",
    name: "Undefined",
  },
];
export interface SelectOption {
  label: string;
  value: string;
  icon?: string;
  groupName?: string;
  customSubMenu?: React.ReactNode;
  customSelectedElement?: React.ReactNode;
}

export enum Operators {
  IS,
  ISNOT,
  BEGINSWITH,
  ENDSWITH,
  CONTAINS,
  ISSET,
  ISNOTSET,
  ISGREATERTHAN,
  ISLESSTHAN,
}

export interface FilterItemAttributes {
  filterType: FilterType | null;
  type: string | null;
  operator: Operators;
  value: string[] | boolean | string | null;
  variable: VariableRecord | null;
  placeholder: string | null;
  review: string | null;
}

/**
 * Filter state
 */
export class FilterItem extends Record<FilterItemAttributes>({
  filterType: null,
  type: null,
  operator: Operators.IS,
  value: true,
  variable: null,
  placeholder: null,
  review: null,
}) {}

export const CSAT_FILTER_OPTIONS = [
  {
    label: "1/5",
    value: "1",
  },
  {
    label: "2/5",
    value: "2",
  },
  {
    label: "3/5",
    value: "3",
  },
  {
    label: "4/5",
    value: "4",
  },
  {
    label: "5/5",
    value: "5",
  },
  {
    label: "Positive",
    value: "ISPOSITIVE",
  },
  {
    label: "Negative",
    value: "ISNEGATIVE",
  },
];

export const ACTIVE_STATE_FILTER_OPTIONS = [
  { label: "Active", value: "true" },
  { label: "Inactive", value: "false" },
];

export const VARIABLE_STATUS_FILTER_OPTIONS = [
  { label: "Used", value: "true" },
  { label: "Unused", value: "false" },
];
