// @ts-strict-ignore
import type Immutable from "immutable";
import { type LDFlagSet } from "launchdarkly-js-client-sdk";
import {
  type Selector,
  createSelector,
  createStructuredSelector,
} from "reselect";

import {
  type Modality,
  VOICE_MODALITY,
} from "components/Shared/Pages/Responses/ResponsesEditor/constants";
import { type MessageBlock } from "reducers/messageBlocks/types";
import { type Platforms } from "reducers/platforms/types";
import { type BlockConfig } from "reducers/responses/messageRecords/types";
import {
  type MESSAGE_RECORDS,
  type MESSAGE_RECORD_FACTORIES,
} from "reducers/responses/reducer";
import { type State } from "reducers/types";
import { type Client, selectClient } from "services/client";
import { selectFlags } from "services/flags";
import { selectActiveModality } from "services/responses/selectors/selectActiveModality";
import { type Entries, type Keys } from "types";

export type MessageType =
  | keyof typeof MESSAGE_RECORDS
  | keyof typeof MESSAGE_RECORD_FACTORIES;

export type BlockConfigMap = {
  [K in MessageType]: BlockConfig;
};

type PartialBlockConfigMap = {
  [K in MessageType]: Omit<BlockConfig, "unsupportedModalities">;
};

const authsSelector: Selector<State, Immutable.Map<string, unknown>> = (
  state: State,
) => state.auths;
const platformsSelector: Selector<State, Platforms> = (state: State) =>
  state.platforms;
const messageBlockSelector: Selector<State, MessageBlock[] | null> = (
  state: State,
) => state.messageBlocks;
const flagsSelector: Selector<State, LDFlagSet | null> = selectFlags;

interface DesiredSelection {
  auths: Immutable.Map<string, unknown>;
  platforms: Platforms;
  messageBlockInfo: MessageBlock[] | null;
  activeModality: Modality;
  client: Client | null;
  flags: LDFlagSet | null;
}

const structuredSelector = createStructuredSelector<State, DesiredSelection>({
  auths: authsSelector,
  platforms: platformsSelector,
  messageBlockInfo: messageBlockSelector,
  activeModality: selectActiveModality,
  client: selectClient,
  flags: flagsSelector,
});

function addUnsupportedModalities(
  messageBlockInfo: MessageBlock[] | null,
  allBlockData: PartialBlockConfigMap,
  activeModality: Modality,
) {
  const fullBlockData = {} as BlockConfigMap;
  // update each block's visibility depending on the currently active modality
  // the source of truth for block channel/modality compatability comes from API
  (Object.entries(allBlockData) as Entries<BlockConfigMap>).forEach(
    ([messageType, blockConfig]) => {
      const messageBlock = messageBlockInfo?.find(
        (b) => b.messageType === messageType,
      );

      if (!messageBlockInfo || !messageBlock) {
        fullBlockData[messageType] = {
          ...blockConfig,
          unsupportedModalities: [],
        };

        return;
      }

      const isUnsupportedForActiveModality =
        messageBlock.unsupportedModality.includes(activeModality);

      fullBlockData[messageType] = {
        ...blockConfig,
        isVisible: !isUnsupportedForActiveModality && blockConfig.isVisible,
        unsupportedModalities: messageBlock.unsupportedModality,
      };
    },
  );

  return fullBlockData;
}

const GENERATIVE_DASHBOARD_SUPPORTED_BLOCK_TYPES = [
  "text",
  "shuffle",
  "conditionals_block",
  "custom_javascript_event",
  "sign_in",
  "sign_out",
  "smart_capture",
  "scheduled_block",
  "variable_override",
  "capture",
  "http_request_recipe",
  "handoff",
  "handoff_integration",
  "helpdesk_handoff_recipe",
  "voice_handoff",
  "zendesk_agent_workspace_handoff",
  "zendesk_support_handoff",
  "zendesk_live_agent",
  "salesforce",
  "salesforce_live_agent",
  "action_integration",
  "list_selection_template",
  "widget",
  "voice_end_call",
  "voice_select_language",
  "link",
];

const PROCESSES_V2_SUPPORTED_BLOCK_TYPES = [
  "text",
  "shuffle",
  "link",
  "generative_handoff",
  "web_action_block",
  "conditionals_block",
  "scheduled_block",
  "process_instruction_block",
  "list_selection_template",
  "variable_override",
];

export const BLOCKS_INCOMPATIBLE_WITH_EMAIL = [
  "process_instruction_block",
  "list_selection_template",
];

export const BLOCKS_INCOMPATIBLE_WITH_VOICE = ["link"];

export const selectBlockConfig = createSelector(
  structuredSelector,
  ({
    client,
    auths,
    platforms,
    messageBlockInfo,
    activeModality,
    flags,
  }): BlockConfigMap => {
    const isVoiceModalityActive = activeModality === VOICE_MODALITY;

    const allBlockData: PartialBlockConfigMap = {
      // TODO BQ-1649: remove this
      text: {
        isVisible: false,
        icon: "Text",
        blockName: isVoiceModalityActive ? "Speak" : "Text",
        blockTitle: isVoiceModalityActive ? "Speak" : "Text Message",
        shortTitle: isVoiceModalityActive ? "Speak" : "Text",
        blockType: "message",
      },
      shuffle: {
        isVisible: true,
        icon: "Text",
        blockName: isVoiceModalityActive ? "Speak" : "Text",
        blockTitle: isVoiceModalityActive ? "Speak" : "Text Message",
        shortTitle: isVoiceModalityActive ? "Speak" : "Text",
        blockType: "message",
      },
      web_window: {
        blockType: "action",
        blockName: "Web Window",
        blockTitle: "Web Window",
        shortTitle: "Web",
        icon: "PopOut",
        isVisible: !isVoiceModalityActive,
      },
      generative_handoff: {
        blockType: "generative_handoffs",
        blockName: "Generative Handoff",
        blockTitle: "Handoff",
        shortTitle: "Handoff",
        icon: "MessageConversation",
        designSystemIconName: "MessageConversation",
        isVisible: true,
      },
      picture: {
        isVisible: !isVoiceModalityActive,
        icon: "Picture",
        blockName: "Picture",
        blockTitle: "Picture Message",
        shortTitle: "Picture",
        blockType: "message",
      },
      video: {
        isVisible: !isVoiceModalityActive,
        icon: "Movie",
        blockName: "Video",
        blockTitle: "Video Message",
        shortTitle: "Video",
        blockType: "message",
      },
      link: {
        isVisible: !isVoiceModalityActive,
        icon: "Link",
        blockName: "Link",
        blockTitle: "Link Message",
        shortTitle: "Link",
        blockType: "message",
      },
      custom_handoff_event: {
        isVisible:
          Boolean(client?.features.custom_handoff_event_block) &&
          !isVoiceModalityActive,
        icon: "Agent",
        blockName: "CustomHandoffEvent",
        blockTitle: "Custom Handoff",
        shortTitle: "JS Handoff",
        blockType: "action",
      },
      custom_javascript_event: {
        blockType: "action",
        blockName: "JavascriptEvent",
        blockTitle: "Fire Event",
        shortTitle: "Fire Event",
        icon: "Code",
        isVisible:
          Boolean(client?.features.custom_javascript_event_block) &&
          !isVoiceModalityActive,
      },
      capture: {
        isVisible: true,
        icon: "MessageDots",
        blockName: isVoiceModalityActive ? "Ask" : "Capture",
        blockTitle: isVoiceModalityActive ? "Ask For" : "Capture Message",
        shortTitle: isVoiceModalityActive ? "Ask" : "Capture",
        blockType: "action",
      },
      handoff: {
        blockType: "action",
        blockName: "Handoff",
        blockTitle: "Email Handoff",
        shortTitle: "Email",
        icon: "Email",
        // legacy block that some clients still have
        isVisible: false,
      },
      handoff_recipe: {
        blockType: "action",
        blockName: "Email Handoff",
        blockTitle: "Email Handoff",
        shortTitle: "Email",
        icon: "Email",
        isVisible: true,
      },
      helpdesk_handoff_recipe: {
        blockType: "action",
        blockName: "Zendesk Ticketing",
        blockTitle: "Zendesk Ticketing",
        shortTitle: "Zendesk Tic...",
        icon: "Zendesk",
        isVisible:
          Boolean(client?.features.zendesk_ticketing) &&
          Boolean(client?.handoff?.integrations?.zendesk?.enabled),
      },
      http_request_recipe: {
        blockType: "action",
        blockName: "HTTP",
        blockTitle: "HTTP Request",
        shortTitle: "Request",
        icon: "Transfers",
        isVisible:
          Boolean(client?.features.personalization) &&
          Boolean(client?.features.request_block),
      },
      zendesk_agent_workspace_handoff: {
        blockType: "action",
        isVisible:
          (Boolean(client?.features.zendesk_agent_workspace) ||
            Boolean(client?.features.ada_glass_zendesk_messaging)) &&
          Boolean(platforms.getIn(["zaw_liveagent", "record", "enabled"])) &&
          !isVoiceModalityActive,
        blockName: "Zendesk Messaging",
        blockTitle: "Zendesk Messaging",
        shortTitle: "Zendesk Mess...",
        icon: "ZendeskMessaging",
      },
      zendesk_support_handoff: {
        blockType: "action",
        isVisible:
          Boolean(flags["zendesk-support-temp"]) &&
          Boolean(
            platforms.getIn(["zendesk_support_liveagent", "record", "enabled"]),
          ) &&
          !isVoiceModalityActive,
        blockName: "Zendesk Support",
        blockTitle: "Zendesk Support",
        shortTitle: "Zendesk Supp...",
        icon: "ZendeskSupportColor",
      },
      base: {
        blockType: "action",
        blockName: "HTTP",
        blockTitle: "HTTP Request",
        shortTitle: "Request",
        icon: "Transfers",
        isVisible: false,
      },
      sign_in: {
        blockType: "action",
        isVisible:
          !isVoiceModalityActive &&
          Boolean(client?.features.personalization) &&
          (Boolean(auths.size) || Boolean(client?.features.authentication)),
        blockName: "Request Sign In",
        blockTitle: "Request Sign In",
        shortTitle: "Sign In",
        icon: "SignIn2",
      },
      sign_out: {
        blockType: "action",
        isVisible:
          !isVoiceModalityActive &&
          Boolean(client?.features.personalization) &&
          (Boolean(auths.size) || Boolean(client?.features.authentication)),
        blockName: "Request Sign Out",
        blockTitle: "Request Sign Out",
        shortTitle: "Sign Out",
        icon: "SignOut",
      },
      scheduled_block: {
        blockType: "operation",
        blockName: "Scheduled Block",
        blockTitle: "Scheduled Block",
        shortTitle: "Scheduled",
        icon: "Clock",
        isVisible: true,
      },
      conditionals_block: {
        isVisible:
          Boolean(client?.features.personalization) &&
          Boolean(client?.features.segmentation),
        blockType: "operation",
        blockName: client?.features.conditionals
          ? "Conditional"
          : "Segmentation",
        blockTitle: client?.features.conditionals
          ? "Conditional"
          : "Segmentation",
        shortTitle: client?.features.conditionals
          ? "Conditional"
          : "Segmentation",
        icon: "Segmentation",
      },
      survey: {
        isVisible:
          Boolean(client?.features.satisfaction) &&
          !client?.features.csat_v2 &&
          !isVoiceModalityActive,
        icon: "ChatHeart",
        blockName: "Satisfaction",
        blockTitle: "Capture Satisfaction",
        shortTitle: "Satisfaction",
        blockType: "action",
      },
      nuance_secure_live_agent: {
        blockType: "action",
        blockName: "Nuance Live Agent",
        blockTitle: "Nuance Live Agent",
        shortTitle: "Nuance Liv...",
        icon: "Nuance",
        isVisible: Boolean(client?.features.ada_glass_nuance),
      },
      zendesk_live_agent: {
        blockType: "action",
        blockName: "Zendesk Live Agent",
        blockTitle: "Zendesk Chat",
        shortTitle: "Zendesk Chat",
        icon: "ZendeskChat",
        isVisible:
          Boolean(client?.features.ada_glass_zendesk) && !isVoiceModalityActive,
      },
      salesforce_live_agent: {
        isVisible:
          Boolean(client?.features.ada_glass_salesforce) &&
          !isVoiceModalityActive,
        icon: "Salesforce",
        blockName: "Salesforce Live Agent",
        blockTitle: "Salesforce Chat",
        shortTitle: "Salesforce Chat",
        blockType: "action",
      },
      redirect_block: {
        blockType: "action",
        blockName: "Redirect",
        blockTitle: "Redirect",
        shortTitle: "Redirect",
        icon: "Redirect",
        isVisible: true,
      },
      list_selection_template: {
        isVisible:
          Boolean(client?.features.selection) &&
          Boolean(client?.features.personalization),
        icon: "Selection",
        blockName: "List Option",
        blockTitle: "List Option",
        shortTitle: "List Option",
        blockType: "operation",
      },
      variable_override: {
        blockType: "operation",
        blockName: "Variable Override",
        blockTitle: "Set Variable",
        shortTitle: "Set Variable",
        icon: "Update",
        isVisible:
          Boolean(client?.features.variable_set_block) &&
          Boolean(client?.features.personalization),
      },
      widget: {
        blockType: "action",
        blockName: "Widget Block",
        blockTitle: "App",
        shortTitle: "App",
        icon: "Widget",
        isVisible: Boolean(client?.features.widget_block),
      },
      salesforce: {
        blockType: "action",
        blockName: "Salesforce API",
        blockTitle: "Salesforce API",
        shortTitle: "Salesforce API",
        icon: "Salesforce",
        isVisible: Boolean(client?.features.integration_block_salesforce),
      },
      quick_replies_block: {
        isVisible: true,
        icon: "QuickReplies",
        blockName: "Quick Replies",
        blockTitle: "Quick Replies",
        shortTitle: "Quick Replies",
        blockType: "action",
      },
      csat_survey: {
        isVisible: Boolean(client?.features.csat_v2) && !isVoiceModalityActive,
        blockName: "Satisfaction",
        blockType: "action",
        shortTitle: "Satisfaction",
        blockTitle: "Satisfaction Survey",
        icon: "HeartSmaller",
      },
      action_integration: {
        // dummy data since this block isn't actually displayed
        isVisible: false,
        icon: "Error",
        blockName: "ActionIntegration",
        blockTitle: "Action Integration",
        shortTitle: "Action Integration",
        blockType: "action_integration",
      },
      unsupported: {
        // dummy data since this block isn't actually displayed
        isVisible: false,
        blockTitle: "Unsupported Message",
        blockType: "action",
        blockName: "Unsupported Message",
        shortTitle: "Unsupported Message",
        icon: "No",
      },
      note: {
        isVisible: true,
        icon: "Note",
        blockName: "Builder Note",
        blockTitle: "Builder Note",
        shortTitle: "Builder Note",
        blockType: "note",
      },
      afm_event: {
        isVisible: Boolean(client?.features.afm_business_events),
        icon: "Event",
        blockName: "Track Event",
        blockTitle: "Track Event",
        shortTitle: "Track Event",
        blockType: "action",
      },
      handoff_integration: {
        // dummy data since this block isn't actually displayed
        isVisible: false,
        icon: "Error",
        blockName: "ActionIntegration",
        blockTitle: "Action Integration",
        shortTitle: "Action Integration",
        blockType: "handoff_integration",
      },
      voice_end_call: {
        isVisible: isVoiceModalityActive,
        icon: "PhoneEndCall",
        blockName: "End Call",
        blockTitle: "End Phone Call",
        shortTitle: "End Call",
        blockType: "action",
      },
      voice_handoff: {
        isVisible: isVoiceModalityActive,
        icon: "PhoneCall",
        blockName: "Transfer Call",
        blockTitle: "Transfer Call",
        shortTitle: "Transfer Call",
        blockType: "action",
      },
      voice_sms: {
        isVisible: isVoiceModalityActive,
        icon: "SMS",
        blockName: "SMS",
        blockTitle: "Send SMS",
        shortTitle: "SMS",
        blockType: "message",
      },
      weighted_random_integer: {
        // dummy data since this block isn't actually displayed
        isVisible: false,
        icon: "",
        blockName: "Weighted Random Integer",
        blockTitle: "Weighted Random Integer",
        shortTitle: "Weighted Random Integer",
        blockType: "message",
      },
      smart_capture: {
        isVisible: isVoiceModalityActive,
        icon: "Sparkles",
        blockName: "Smart Capture",
        blockTitle: "Smart Capture",
        shortTitle: "Smart Capture",
        blockType: "action",
      },
      web_action_block: {
        isVisible: true,
        icon: "Lightning",
        blockName: "",
        blockTitle: "",
        shortTitle: "",
        blockType: "action",
        designSystemIconName: "Lightning",
      },
      process_instruction_block: {
        // eslint-disable-next-line no-restricted-syntax
        isVisible: client.product_type === "generative",
        icon: "",
        blockName: "Instruction",
        blockTitle: "Instruction",
        shortTitle: "Instruction",
        blockType: "operation",
        designSystemIconName: "Lightbulb",
      },
    } as const;

    return addUnsupportedModalities(
      messageBlockInfo,
      allBlockData,
      activeModality,
    );
  },
);

export const selectBlockConfigGenerative = createSelector(
  (state) => state.router.location.pathname,
  flagsSelector,
  selectBlockConfig,
  (pathname, flags, blockConfig): BlockConfigMap => {
    const isProcesses = pathname.includes("/content/processes-blocks/");
    let validBlocks = GENERATIVE_DASHBOARD_SUPPORTED_BLOCK_TYPES;

    if (isProcesses) {
      validBlocks = PROCESSES_V2_SUPPORTED_BLOCK_TYPES;

      if (flags?.train_processes_capture_block) {
        validBlocks = [...validBlocks, "capture"];
      }
    }

    const entries = Object.entries(blockConfig).map(([messageType, config]) => {
      if (!validBlocks.includes(messageType)) {
        return [messageType, { ...config, isVisible: false }];
      }

      return [messageType, config];
    });

    return Object.fromEntries(entries);
  },
);

/**
 * Selector returning an array of blocks, filtered by visibility
 */
export const visibleBlocksSelector = createSelector(
  selectBlockConfig,
  (blockConfig) =>
    (Object.keys(blockConfig) as Keys<BlockConfigMap>)
      .filter((messageType) => {
        const config = blockConfig[messageType];

        return (
          ["action", "message"].includes(config.blockType) && config.isVisible
        );
      })
      .map((messageType) => ({ messageType, ...blockConfig[messageType] })),
);
