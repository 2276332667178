import { type AxiosResponse } from "axios";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { updatePlatform } from "actions";
import { createAlert } from "actions/alerts";
import { AlertType } from "actions/alerts/types";
import { closeModal } from "actions/modal";
import { type ZendeskSupportPlatformAttributes } from "reducers/platforms/models/ZendeskSupportPlatformRecord";
import { type Platform } from "reducers/platforms/types";
import { adaAPI } from "services/api";
import { keyConverter } from "services/key-converter";

// import { IntroScreen } from "./IntroScreen";
import { SettingsScreen } from "./SettingsScreen";

import "./style.scss";

// TODO: Since introscreen is not used, we can probably remove all the screen related code
// const INTRO_SCREEN = 1;
// const SETTINGS_SCREEN = 2;

interface ApiError {
  response: { data: { message: string } };
}

export const ZendeskSupportModal = () => {
  const platformType = "zendesk_support_liveagent";
  const platform = useSelector((state) =>
    state.platforms.get(platformType),
  ) as Platform;
  const dispatch = useDispatch();

  // const initialScreen = platform.get("isPublished")
  //   ? SETTINGS_SCREEN
  //   : INTRO_SCREEN;
  // const [modalScreen, setModalScreen] = React.useState(initialScreen);
  const [errorInfo, setErrorInfo] = React.useState(undefined);

  const isSaveDisabled =
    (platform.get("isPublished") && !platform.get("isDirty")) ||
    !platform.get("isValid") ||
    platform.get("isLoading");

  const updateZendeskSupportPlatform = (
    payload: Partial<ZendeskSupportPlatformAttributes>,
  ) => {
    dispatch(updatePlatform(platformType, payload));
  };

  const onSaveSuccess = (response: AxiosResponse) => {
    setErrorInfo(undefined);
    dispatch({ type: "PLATFORM_UPDATE_SUCCESS", response });
    dispatch(
      createAlert({
        message: "Successfully saved platform.",
        alertType: AlertType.SUCCESS,
      }),
    );
    dispatch(closeModal());
  };

  const onSaveError = (error: ApiError) => {
    dispatch(
      updatePlatform(
        platformType,
        {},
        {
          isLoading: false,
        },
      ),
    );
    let apiError;

    try {
      apiError = JSON.parse(error.response.data.message);
    } catch (e) {
      apiError = { type: "generic", message: error.response.data.message };
    }

    setErrorInfo(apiError);
  };

  const saveClientPlatform = async (platformToSave: Platform) => {
    const isPublished = platformToSave.get("isPublished");
    dispatch(
      updatePlatform(
        platformType,
        {},
        {
          isLoading: true,
        },
      ),
    );

    try {
      const response = await adaAPI.request({
        url: isPublished ? `/platforms/${platformType}/` : "/platforms/",
        method: isPublished ? "patch" : "post",
        data: keyConverter(platformToSave.get("record").toJS(), "underscore"),
      });
      onSaveSuccess(response);
    } catch (error) {
      onSaveError(error as ApiError);
    }
  };

  return (
    <div className="SettingsPlatformsZendeskSupportModal Modal__modal">
      <h5 className="Modal__title">Configure Zendesk Support</h5>
      <SettingsScreen
        saveClientPlatform={saveClientPlatform}
        updateZendeskSupportPlatform={updateZendeskSupportPlatform}
        isSaveDisabled={isSaveDisabled}
        closeModal={closeModal}
        platform={platform}
        errorInfo={errorInfo}
      />
    </div>
  );
};
