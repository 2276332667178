import Immutable from "immutable";

export interface ZendeskSupportPlatformAttributes {
  name: string;
  fullName: string;
  enabled: boolean;
  auth: {
    subdomain?: string;
    email?: string;
    apiToken?: string;
    adaToken?: string;
  };
  isVisibleToClient: boolean;
}

class ZendeskSupportPlatformRecord extends Immutable.Record({
  name: "zendesk_support_liveagent",
  fullName: "Zendesk Support",
  enabled: true,
  auth: Immutable.Map({
    subdomain: "",
    email: "",
    apiToken: "",
    // adaToken default value is omitted to allow the server to generate this on save
  }),

  isVisibleToClient(): boolean {
    return true;
  },
}) {}

export { ZendeskSupportPlatformRecord };
