import { primitives } from "@adasupport/byron";
import styled, { css } from "styled-components";

import { cssVariables } from "constants/css";

const noFlex = css`
  flex-shrink: 0;
  flex-grow: 0;
`;

export const VSpace = styled.div<{ size: keyof typeof primitives.spacing }>`
  height: ${(p) => primitives.spacing[p.size]};
  ${noFlex};
`;

/** @deprecated Use VSpace with size prop */
export const VSpace1 = styled.div`
  height: ${primitives.spacing.space1};
  ${noFlex};
`;
/** @deprecated Use VSpace with size prop */
export const VSpace2 = styled.div`
  height: ${primitives.spacing.space2};
  ${noFlex};
`;
/** @deprecated Use VSpace with size prop */
export const VSpace3 = styled.div`
  height: ${primitives.spacing.space3};
  ${noFlex};
`;
/** @deprecated Use VSpace with size prop */
export const VSpace4 = styled.div`
  height: ${primitives.spacing.space4};
  ${noFlex};
`;
/** @deprecated Use VSpace with size prop */
export const VSpace5 = styled.div`
  height: ${primitives.spacing.space5};
  ${noFlex};
`;
/** @deprecated Use VSpace with size prop */
export const VSpace6 = styled.div`
  height: ${primitives.spacing.space6};
  ${noFlex};
`;
/** @deprecated Use VSpace with size prop */
export const VSpace8 = styled.div`
  height: ${primitives.spacing.space8};
  ${noFlex};
`;
/** @deprecated Use VSpace with size prop */
export const VSpace9 = styled.div`
  height: ${primitives.spacing.space9};
  ${noFlex};
`;
/** @deprecated Use VSpace with size prop */
export const VSpace10 = styled.div`
  height: ${primitives.spacing.space10};
  ${noFlex};
`;
/** @deprecated Use VSpace with size prop */
export const VSpace12 = styled.div`
  height: ${primitives.spacing.space12};
  ${noFlex};
`;
/** @deprecated Use VSpace with size prop */
export const VSpace14 = styled.div`
  height: ${primitives.spacing.space14};
  ${noFlex};
`;

export const HSpace = styled.div<{ size: keyof typeof primitives.spacing }>`
  display: inline-block;
  width: ${(p) => primitives.spacing[p.size]};
  ${noFlex};
`;

/** @deprecated Use HSpace with size prop */
export const HSpace1 = styled.span`
  display: inline-block;
  width: ${primitives.spacing.space1};
  ${noFlex};
`;

/** @deprecated Use HSpace with size prop */
export const HSpace2 = styled.span`
  display: inline-block;
  width: ${primitives.spacing.space2};
  ${noFlex};
`;
/** @deprecated Use HSpace with size prop */
export const HSpace4 = styled.span`
  display: inline-block;
  width: ${primitives.spacing.space4};
  ${noFlex};
`;
/** @deprecated Use HSpace with size prop */
export const HSpace5 = styled.span`
  display: inline-block;
  width: ${primitives.spacing.space5};
  ${noFlex};
`;
/** @deprecated Use HSpace with size prop */
export const HSpace8 = styled.span`
  display: inline-block;
  width: ${primitives.spacing.space8};
  ${noFlex};
`;
/** @deprecated Use HSpace with size prop */
export const HSpace12 = styled.span`
  display: inline-block;
  width: ${primitives.spacing.space12};
  ${noFlex};
`;

/** @deprecated Use VSpace with size prop */
export const Space4 = styled.div`
  height: ${cssVariables.space4};
`;

/** @deprecated Use VSpace with size prop */
export const Space5 = styled.div`
  height: ${cssVariables.space5};
`;

/** @deprecated Use VSpace with size prop */
export const Space6 = styled.div`
  height: ${cssVariables.space6};
`;

/** @deprecated Use VSpace with size prop */
export const Space14 = styled.div`
  height: ${primitives.spacing.space14};
`;
