// @ts-strict-ignore
import Immutable, { type Record } from "immutable";

import { type Platforms } from "reducers/platforms/types";
import { type ClientLegacy } from "resourceModels/Client";
import { type MessageType } from "selectors/blocks";

import { type BlockConfig } from "../types";

export interface BaseMessageRecordAttributes {
  displayType?: MessageType;
  id?: string | null;
  isLiveAgentHandoff?: boolean;
  isLoading?: boolean;
  isNew?: boolean;
  locked?: boolean;
  reviewableMessage?: boolean;
  variableId?: string | null;
  variablesData?: Immutable.List<Immutable.Map<string, unknown>>;
  reachableWhen?: Immutable.List<Immutable.Map<string, unknown>>;
}

const baseFields: BaseMessageRecordAttributes = {
  id: null,
  isLoading: false,
  locked: false,
  reviewableMessage: false,
  variableId: null,
};

export interface BaseMessageRecordInstance {
  type: MessageType;
  isNew?: boolean;
  invalidFields: Immutable.List<string>;
}

interface BlockConfigParams {
  auths: Immutable.Map<string, unknown>;
  client: ClientLegacy;
  platforms: Platforms;
}

export type BlockConfigGetter = (params: BlockConfigParams) => BlockConfig;
export type InvalidFieldsGetter<T = null> = (
  message: T,
) => Immutable.List<string>;

type BaseMessageRecordClassInstance<T extends object> = Record.Instance<T> &
  Readonly<T> &
  BaseMessageRecordInstance;

interface BaseMessageRecordClass<T extends object> {
  new (values: Partial<T>): BaseMessageRecordClassInstance<T>;
  getInvalidFields: InvalidFieldsGetter<BaseMessageRecordClassInstance<T>>;
  blockConfig: BlockConfigGetter;
}

type KnownAttributes = BaseMessageRecordAttributes & { type: MessageType };

// TODO: The whole thing must go away
// eslint-disable-next-line import/no-default-export
export default function BaseMessageRecord<T extends object>(
  defaultAttributes: { type: string } & T,
): BaseMessageRecordClass<T> {
  const newDefaultAttributes = {
    ...baseFields,
    ...defaultAttributes,
  };

  class MessageRecord extends Immutable.Record(
    newDefaultAttributes as KnownAttributes,
  ) {
    cache: {
      invalidFields?: Immutable.List<string>;
    };

    get invalidFields(): Immutable.List<string> {
      const { getInvalidFields } = this
        .constructor as BaseMessageRecordClass<T>;

      // We store results of getters' calculations in this.cache
      // TODO: The whole thing must go away
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      this.cache = this.cache || {};

      // Check if already was calculated
      if (this.cache.invalidFields) {
        return this.cache.invalidFields;
      }

      // If there's no validation function, return an empty List, but throw a warning
      // TODO: This must go away
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!getInvalidFields) {
        window.console.warn(
          `getInvalidFields function not defined for "${this.type}" block`,
        );

        return Immutable.List();
      }

      // Store results in cache
      this.cache.invalidFields = getInvalidFields(this);

      // And return them
      return this.cache.invalidFields;
    }
  }

  // this is a workaround for when Typescript doesn't want to convert types
  return MessageRecord as unknown as BaseMessageRecordClass<T>;
}

// A helper type for type casting to overcome TS2590 error
// thrown by Immutable#merge method over large union types.
// P.S. We should really get rid of Immutable ASAP 🤬
export type BaseImmutableMessageRecord =
  Immutable.Record.Instance<BaseMessageRecordAttributes>;
