import { AdaApiSlice } from "slices";

import { type Variable } from "./types";

const AdaApiSliceWithTags = AdaApiSlice.enhanceEndpoints({
  addTagTypes: ["Variable"],
});

export const variablesApi = AdaApiSliceWithTags.injectEndpoints({
  endpoints: (build) => ({
    getVariables: build.query({
      query: ({ scope, includeRules, includeActions }) => ({
        method: "GET",
        url: "/v2/variable",
        params: {
          scope,
          include_rules: includeRules,
          include_actions: includeActions,
        },
      }),
      transformResponse: (response: { variables: Variable[] }) =>
        response.variables,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ _id: id }) => ({
                type: "Variable" as const,
                id,
              })),
              { type: "Variable", id: "LIST" },
            ]
          : [{ type: "Variable", id: "LIST" }],
    }),
    createVariable: build.mutation<
      { _id: string },
      Pick<Variable, "name" | "scope" | "description" | "_type">
    >({
      query: (variable) => ({
        method: "POST",
        url: "/variables",
        data: variable,
      }),
      transformResponse: (response: { variable: Variable }) => ({
        _id: response.variable._id,
      }),
      invalidatesTags: [{ type: "Variable", id: "LIST" }],
    }),
    editVariable: build.mutation<
      Variable,
      Pick<
        Variable,
        | "_id"
        | "name"
        | "description"
        | "always_ask"
        | "capture_modes"
        | "voice_expected_response_type"
      >
    >({
      query: (variable) => ({
        method: "PATCH",
        url: `/variables/${variable._id}`,
        data: variable,
      }),
      invalidatesTags: [{ type: "Variable", id: "LIST" }],
    }),
    deleteVariable: build.mutation({
      query: (id: string) => ({
        method: "DELETE",
        url: `/v2/variable/${id}`,
      }),
      invalidatesTags: [{ type: "Variable", id: "LIST" }],
    }),
    deleteVariables: build.mutation({
      query: (ids: string[]) => ({
        method: "DELETE",
        url: "/v2/variable/bulk_delete",
        data: { variable_ids: ids },
      }),
      invalidatesTags: [{ type: "Variable", id: "LIST" }],
    }),
  }),
});

export const {
  useGetVariablesQuery,
  useCreateVariableMutation,
  useEditVariableMutation,
  useDeleteVariableMutation,
  useDeleteVariablesMutation,
} = variablesApi;
