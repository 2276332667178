import Immutable from "immutable";

import BaseMessageRecord, {
  type BaseMessageRecordAttributes,
  type InvalidFieldsGetter,
} from "../BaseMessageRecord";

interface ZendeskSupportHandoffAttributes extends BaseMessageRecordAttributes {
  type: "zendesk_support_handoff";
  isLiveAgentHandoff: true;
  fallbackResponseId: string | null;
}

export class ZendeskSupportHandoffMessageRecord extends BaseMessageRecord<ZendeskSupportHandoffAttributes>(
  {
    type: "zendesk_support_handoff",
    isLiveAgentHandoff: true,
    fallbackResponseId: null,
  },
) {
  static getInvalidFields: InvalidFieldsGetter<ZendeskSupportHandoffMessageRecord> =
    (message) => {
      const invalidFields = new Set<string>();

      if (!message.fallbackResponseId) {
        invalidFields.add("fallbackResponseId");
      }

      return Immutable.List(invalidFields);
    };
}
